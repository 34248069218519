.erreur404 h3 {
    margin-bottom: 0.5em;
    font-size: 60px;
}

.erreur404 p, .erreur404 a{
    display: flex;
    justify-content: center;
    margin: auto;
}

.erreur404 a{
    margin: 1em auto;
}

.erreur404 a:hover{
    text-decoration: underline;
    
}

.astronaute {
    position: absolute;
    z-index: 1000;
    animation: moveAstronaute 30s linear infinite;
    animation-fill-mode: forwards; /* Conserve la position finale de l'animation */
  }

  
.astronaute-img {
  width: 100px;
  animation: rotateAstronaute 30s linear infinite;
}
  
  @keyframes moveAstronaute {
    0%, 100% {
      left: 0; /* Position de départ à gauche */
      top: 100px; /* Position de départ en haut */
    }
    50% {
      left: calc(100% - 100px); /* Position finale à droite */
      top: 500px; /* Position maximale en bas */
    }
  }

  @keyframes rotateAstronaute {
    0%, 100% {
      transform: rotate(0deg); /* Pas de rotation */
    }
    50% {
      transform: rotate(360deg); /* Rotation complète (1 tour complet) */
    }
  }

  .astronaute-bulle {
    visibility: hidden;
    font-size: 20px;
  }
  
  .astronaute:hover .astronaute-bulle {
    visibility: visible;
  }
  
  
  
  