#pageDeLogin{
    max-width:100%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formContact{
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    width: 30%;
}

.formContact label{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-top: 1em;
    font-weight: bold;
}

.formContact input {
    margin-top: 0.2em;
    height: 2.5em;
    font-size: 18px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
    border : none;
}


.boutonConnecter{
    width:30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #002d56 0%, #0355a2 51%, #002d56 100%);
    border-radius: 10px;
    border: none;
    color: #fff;
}

.boutonConnecter:hover{
    background-position: right center;
    cursor: pointer;
}

.boutonConnecter input {
    height: 3em;
}