* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: 'GeosansLight', sans-serif;
}

a, p {
  color: black;
}

body {
  max-width: 90em;
  min-width: 24em;
  margin: auto;
}

main {
  font-size: 22px;
  font-weight: bold;
  text-align: justify;
  margin: 2em auto;
}

main h3 {
  color: #002d56;
  font-size: 2.5rem;
  text-align: center;
}

.pageRedaction p, .pageMesConferences p, .pageMedias p, .pageMesProjets p {
  font-size: 24px;
}


/* RESPONSIVE MOBILE */
@media screen and (max-width: 45em) {
  main {
    font-size: 20px;
}

main h3 {
  font-size: 2rem;
}
}