#pageDashboard{
    max-width: 75%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boutons-admin {
    display: flex;
    margin: 4rem auto;
    gap : 1em;
}

.btn-admin{
    width: 100%;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #002d56 0%, #0355a2 51%, #002d56 100%);
    border-radius: 10px;
    border: none;
    font-size: 26px;
    color: #fff;
    padding: 0.5em;
}

.btn-admin:hover{
    background-position: right center;
    cursor: pointer;
}

.boutonCbtn-admin input {
    height: 3em;
}

.deconnexion {
    color: #002d56;
    cursor: pointer;
    margin: 1.5em auto;
}

/* Modale générale */

.header-admin {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.header-admin .fa-circle-xmark{
    color: #002d56;
    font-size: 40px;
    cursor: pointer;
}

.confiance_logo{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo_box{
    width: 7rem;
    object-fit: contain;
    margin: 1rem;
}

.logo-admin-modif{
    display: flex;
    gap : 0.5em;
    margin-bottom: 0.5em;
}

.img-admin {
    width: 100%;
    height: 150px;
    object-fit: cover;
    }

 .add{
        display: flex;
        gap : 0.5em;
        font-size: larger;
        cursor: pointer;
        color: #002d56;
        margin-bottom: 2em;
    }

.add p{
    margin-bottom: 0.5em;
}


    
/* Modale des logo */
.logo-admin {
    display: flex;
    flex-direction: row;
    justify-items: center;
}

.fa-trash-can, .fa-file-pen, .fa-arrows-up-down-left-right{
    color: #002d56;
    font-size: 25px;
    cursor: pointer;
}

.conf-modal{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 2em;
}

.conf-admin{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.conf-admin h4 {
    font-size: 24px;
}

/* Formulaire d'ajout */
.form-modal{
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: bold;
    color: #002d56;
    margin-bottom: 2em;
    
}

.form-modal label, .form-modal input, .form-modal textarea, .form-modal button {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding: 0.5em;
}

.form-modal button {
    width: 20%;
    align-items: center;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    cursor: pointer;
}


/* Modale de modification */

.edit-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height:100%;
    background-color: #fff;
}

.edit-modal--header {
    display: flex;
    margin: 2em 7em;
    justify-content: space-between;
    align-items: center;
}

.edit-modal--header i{
    color: #002d56;
    font-size: 36px;
    cursor: pointer;
}

.edit-modal form {
    display: flex;
    flex-direction: column;
    padding: 0 5em;
    font-size: 22px;
    font-weight: bold;
    color: #002d56;
}

.edit-modal input, .edit-modal textarea{
    margin-bottom: 1em;
    font-size: 20px;
    border: 0.5px solid black;
    padding: 0.5em;
    width: 100%;
}

.edit-modal textarea {
    height: 5em;
    resize: vertical;
    overflow: auto;
}

.edit-modal button {
    width: 20%;
    font-size: 22px;
    font-weight: bold;
    color: #002d56;
    margin-bottom: 1em;
    cursor: pointer;
    padding: 0.5em;
}